import {
  Box,
  Button,
  Grid,
  Icon,
  Typography,
  Dialog,
  Select,
  Input,
  Tooltip,
} from "@ntpkunity/controls";
import { Skeleton, useTheme } from "@mui/material";
import { PageHeader } from "./orderDetailPageHeaderStyle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "router/path";
import { useEffect, useState, FC } from "react";
import DisableLoader from "../../../shared/assets/images/loader-disabled.gif";
import {
  useCustomerOrderData,
  useUpdateOrderStatus,
} from "common/hooks/useCustomerOrderData";
import {
  useChecklistResponseVerification,
  useGetConfiguredReasons,
  useGetTenantConfiguredInformation,
} from "common/hooks/useConfigurationData";
import { Controller } from "react-hook-form";
import { useStoreContext } from "store/storeContext";
import {
  RETURN_CANCEL_MESSAGE,
  SUBSCRIPTION_CANCEL_MESSAGE,
  UPDATE_DATA_MESSAGE,
} from "common/constants";
import { getInternalAccessToken } from "utilities/methods";
import { OrderStatus } from "enums/OrderStatus";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { CancelButtonWrap } from "@shared/CancelButtonWrap";
import { CutomeTooltip } from "@modules/customerDetails/components/customerDetailPageHeaderTooltip";
import {
  useGetContractByRefercnceId,
  useUpdateInternalSubscription,
} from "common/hooks/useSubscription";
import { ChecklistResponse, OrderTabName, SubscriptionStatus } from "enums";
import { ToastMessages } from "enums/ToastMessages";
import { useCreateOrderChecklist } from "common/hooks/useCustomerData";
import { ReturnTypes } from "enums/ReturnTypes";
import { useCancelReturnRequest } from "common/hooks/useReturnData";
import { ValidationMessages } from "enums/ValidationMessages";

export const OrderDetailPageHeader: FC<{
  form: any;
  isLoading;
  hideButton;
  addressDirty;
  onSubmit?: (data: any) => unknown;
  initiateReturnDialog?: boolean;
  setInitiateReturnDialog?: any;
  initiateUnwindDialog?: boolean;
  setInitiateUnwindDialog?: any;
  returnLoading?: boolean;
  updateReturnDialog?: boolean;
  setUpdateReturnDialog?: any;
  buyOutDialog: boolean;
  setBuyOutDialog: any;
  isTabLoaded:string;
  setConvertedRetrnType:any;
  convertedReturnType:string
  setConvertETDialogOpened:any;
  convertETDialogOpened:boolean;
  convertUnwindLoading: boolean;
}> = ({
  form,
  isLoading,
  onSubmit,
  hideButton,
  addressDirty,
  initiateReturnDialog,
  setInitiateReturnDialog,
  initiateUnwindDialog,
  setInitiateUnwindDialog,
  returnLoading,
  updateReturnDialog,
  setUpdateReturnDialog,
  setBuyOutDialog,
  buyOutDialog,
  isTabLoaded,
  setConvertedRetrnType,
  convertedReturnType,
  setConvertETDialogOpened,
  convertETDialogOpened,
  convertUnwindLoading,
}) => {
  const theme = useTheme();
  const params = useParams();
  const userId = params?.reference_id;
  const reference_id = params?.reference_id;
  const order_reference_id = params?.order_reference_id;
  const [orderData, setCustomerOrderData] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [contractIdentifier, setContractIdentifier] = useState<any>();
  const [contractStatus, setContractStatus] = useState<any>();
  const [returnIdentifier, setReturnIdentifier] = useState("");
  const [updateInsauranceDetail, setUpdateInsuracneDetail] = useState(false);
  const [convertUnwindToETButton, setConvertUnwindToETButton] = useState(false);
  const [returnType, setReturnType] = useState("");

  const editInsuaranceDetail = useHasPermissions(
    ccpPermissions.FIELDS.EDIT_INSURANCE_DETAIL,
    PermissionTypes.DISABLED
  );
  const convertUnwindToET = useHasPermissions(
    ccpPermissions.FIELDS.CONVERT_UNWIND_INTO_ET_BUTTON,
    PermissionTypes.VIEW
  );

  const { data: getCustomerOrderData } = useCustomerOrderData(userId);
  const { data: contractData } = useGetContractByRefercnceId(
    reference_id,
    order_reference_id
  );
    useGetTenantConfiguredInformation();
    const {data: tenantInformation} = useGetTenantConfiguredInformation()
  const companyId = tenantInformation?.id
  const {
    mutate: checklistResponseVerification,
    isLoading: verificationLoading,
  } = useChecklistResponseVerification(companyId);
  const { mutate: createOrderChecklist, isLoading: createChecklistLoading } =
    useCreateOrderChecklist();

  useEffect(() => {
    setUpdateInsuracneDetail(editInsuaranceDetail);
  }, [editInsuaranceDetail]);

  useEffect(() => {
    setConvertUnwindToETButton(convertUnwindToET);
  }, [convertUnwindToET]);

  useEffect(() => {
    if (getCustomerOrderData?.data) {
      setCustomerOrderData(getCustomerOrderData?.data?.orders);
    }
  }, [getCustomerOrderData?.data]);
  useEffect(() => {
    if (orderData) {
      setSelectedOrder(orderData?.find((f) => f.id === order_reference_id));
    }
  }, [orderData]);
  useEffect(() => {
    if (contractData) {
      setContractIdentifier(contractData?.identifier);
      setContractStatus(contractData?.subscription_status);
      setReturnIdentifier(contractData?.returns?.identifier);
    }
  }, [contractData]);  
  const [dialogOpened, setDialogOpened] = useState<boolean>();
  const [returnDialogOpened, setReturnDialogOpened] = useState<boolean>(false);
  const [cancelSubscriptionDialog, setSubscriptionDialog] = useState<boolean>();
  const { mutate: getAllConfiguredReasons, isLoading: isLoadingGet } =
    useGetConfiguredReasons();
  const { mutate: updateOrderStatus, isLoading: saveButtonLoading } =
    useUpdateOrderStatus();
  const { mutate: cancleSubscription, isLoading: subscriptionLoading } =
    useUpdateInternalSubscription();
  const { mutate: cancelReturRequest, isLoading: cancelReturnLoading } =
    useCancelReturnRequest();
  const [configureReason, setConfigureReason] = useState<
    { value: string; text: string }[]
  >([]);
  const [hideCancelButton, setHideCancelButton] = useState(true);
  const tokenInforamtion = getInternalAccessToken();
  useEffect(() => {
    getAllConfiguredReasons(
      {},
      {
        onSuccess: ({ data }) => {
          data = [
            ...data,
            { reason_name: "OTHER", reason_description: "Other" },
          ];
          setConfigureReason(
            data.map((item) => ({
              value: item.reason_name,
              text: item.reason_description,
            }))
          );
        },
      }
    );
  }, []);
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = form;
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const { state } = useLocation();
  const handleDoneEvent = (data: any) => {
    if (getValues("reason") == "OTHER" && getValues("cancelleation_detail").valueOf()?.length > 100) {
      setError("cancelleation_detail", {message: ValidationMessages.CANCELLATION_REASON_LENGTH})
  } else {
    updateOrderStatus(
      {
        orderId: params?.order_reference_id,
        status: OrderStatus.CANCELED,
        cancellationCode: data?.reason,
        cancellationReason: data?.reason == "OTHER" ? data.cancelleation_detail : null,
        cancelledBy: tokenInforamtion?.user_name,
        // subscriptionId: "string"
      },
      {
        onSuccess () {
          reset({ ...getValues() });
          actions.setToast({
            toastMessage: UPDATE_DATA_MESSAGE,
            toastState: true,
          });
          setDialogOpened(false);
          setHideCancelButton(false);
        },
        onError (error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  }
  };
  const handleCancleSubscription = () => {
    cancleSubscription(
      {
        subscription_status: SubscriptionStatus.CANCELED,
        updated_by: tokenInforamtion?.user_name,
        identifier: contractIdentifier,
      },
      {
        onSuccess () {
          actions.setToast({
            toastMessage: SUBSCRIPTION_CANCEL_MESSAGE,
            toastState: true,
          });
          setSubscriptionDialog(false);
        },
        onError (error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const handleCancelReturnRequest = () => {
    cancelReturRequest(
      {
        updated_by: tokenInforamtion?.user_name,
        identifier: returnIdentifier,
      },
      {
        onSuccess () {
          actions.setToast({
            toastMessage: RETURN_CANCEL_MESSAGE,
            toastState: true,
          });
          setReturnDialogOpened(false);
        },
        onError () {
          actions.setToast({
            toastMessage: "Something went wrong while Canceling Return Request",
            toastState: true,
            variant: "error",
          });
          setReturnDialogOpened(false);
        },
      }
    );
  };
  const createChecklist = (data) => {
    createOrderChecklist(data.checklist, {
      onSuccess () {
        data?.returns?.return_type === ReturnTypes.UNWIND
          ? setInitiateUnwindDialog(true)
          : setBuyOutDialog(true);
      },
      onError (error) {
        actions.setToast({
          toastMessage: error?.detail?.message?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    });
  };

  const handleCheckListRepsoneVerification = (data) => {
    const verificationData = {
      payload: [
        {
          checklist_name: data.checklist?.[0]?.checklist_name,
          response: data.checklist.map((checklist) => {
            return {
              question: checklist?.question_text,
              answer: checklist?.primary_answer,
            };
          }),
        },
      ],
    };
    checklistResponseVerification(verificationData, {
      onSuccess (res) {
        if (res?.[0]?.status === ChecklistResponse.FAVOURABLE) {
          createChecklist(data);
        } else {
          actions.setToast({
            toastMessage: ToastMessages.Checklist_Response_Failure,
            toastState: true,
            variant: "error",
          });
        }
      },
      onError () {
        actions.setToast({
          toastMessage: "Checklist Responses Required",
          toastState: true,
          variant: "error",
        });
      },
    });
  };

  const handleSubmitRequest = (data) => {
    setReturnType(data?.returns?.return_type)
    if(isTabLoaded === OrderTabName[OrderTabName.INSURANCE_DETAILS]){
      return onSubmit(data);
    }else {
    switch (contractData?.subscription_status) {
      case SubscriptionStatus.ACTIVE:
      case SubscriptionStatus.PAUSED:
        return setInitiateReturnDialog(true);
      case SubscriptionStatus.PROCESSING_RETURN:
        return setUpdateReturnDialog(true);
      case SubscriptionStatus.PENDING_RETURN:
        return data?.buyOutOption === "Yes" 
          ? handleCheckListRepsoneVerification(data)
          : onSubmit(data);
    }
  }
  };
  const getSubmitButtonText = () => {
    if(isTabLoaded === OrderTabName[OrderTabName.INSURANCE_DETAILS]){
    return "Save Changes"
    }else{
     switch (contractData?.subscription_status) {
       case SubscriptionStatus.ACTIVE :
         case SubscriptionStatus.PAUSED:
           return "Initiate Return"
       default :
        return "Save Changes"
     }
    }
   }
   const getTypoGraphyText = () => {
    switch (true) {
      case initiateReturnDialog && returnType === ReturnTypes.EARLY_RETURN:
        return "Are you sure you want to allow this customer to return early?";
      case initiateReturnDialog && returnType === ReturnTypes.UNWIND:
          return "Are you sure you want to initiate an unwind?";
      case cancelSubscriptionDialog:
        return "This is an irreversible action and will also cancel the Stripe subscription. No amount will be refunded on Stripe against this action. Are you sure to cancel?";
      case updateReturnDialog:
        return "If confirmed then the changes will be saved and final bill will be updated";
      case initiateUnwindDialog:
        return "You are about to initiate an Unwind process against this subscription, please confirm to proceed?";
      case buyOutDialog:
        return "Do you confirm that the customer wants to purchase this vehicle instead of returning?";
      default:
        return "";
    }
  };

  const handleOnClick = (data) => {
    switch (true) {
      case cancelSubscriptionDialog:
        return handleCancleSubscription();
      default:
        return onSubmit(data);
    }
  };
  return (
    <>
      <PageHeader theme={theme} className="page-header border-bottom">
        <Box theme={theme} className="title">
          <Button
            defaultBtn
            theme={theme}
            iconText={
              <Icon
                name="IconLeftArrow"
                onClick={() =>
                  state?.routeState === true || state?.isWorkQueue === true
                    ? navigate(APP_ROUTES.CCP_WORKQUEUE)
                    : navigate(
                        APP_ROUTES.CUSTOMER_DETAILS +
                          "/" +
                          reference_id +
                          "?tab=orders"
                      )
                }
              />
            }
          />
          <Typography
            variant="h3"
            component="h3"
            sx={{ fontSize: 36 }}
            rowGap={5}
            theme={theme}
          >
            Order ID: {selectedOrder && selectedOrder?.id}
          </Typography>
          <Tooltip
            theme={theme}
            disableFocusListener
            title={<CutomeTooltip isLoading={isLoading} />}
            disablePortal={true}
          >
            <Button
              defaultBtn
              theme={theme}
              disabled
              iconText={<Icon name="InfoIcon" />}
            ></Button>
          </Tooltip>
        </Box>
        <Box theme={theme} className="header-right-btn">
          {hideCancelButton &&
            selectedOrder?.status !== "CANCELED" &&
            selectedOrder?.status !== "COMPLETE" && (
              <CancelButtonWrap>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel Order"
                  onClick={() => {
                    setDialogOpened(true);
                  }}
                />
              </CancelButtonWrap>
            )}
          {contractStatus === SubscriptionStatus.DRAFT && (
            <Button
              theme={theme}
              secondary
              text="Cancel Subscription"
              onClick={() => {
                setSubscriptionDialog(true);
              }}
            />
          )}
          {contractStatus === SubscriptionStatus.PENDING_RETURN &&
            contractData?.returns?.status === "PENDING_RETURN" &&
            contractData?.returns?.return_type !== ReturnTypes.STANDARD_RETURN &&
              contractData?.returns?.return_type !==ReturnTypes.UNWIND && 
              (
              <Button
                theme={theme}
                secondary
                text="Cancel Return"
                onClick={() => {
                  setReturnDialogOpened(true);
                }}
              />
            )}
         {(contractData !== undefined &&
            contractStatus !== SubscriptionStatus.PENDING_RETURN &&
            contractData?.subscription_status !== SubscriptionStatus.ACTIVE &&
            contractData?.subscription_status !== SubscriptionStatus.CLOSED &&
            contractData?.returns?.return_type !== ReturnTypes.EARLY_RETURN &&
            contractData?.returns?.return_type !== ReturnTypes.STANDARD_RETURN &&
            isTabLoaded === OrderTabName[OrderTabName.RETURN] &&
            convertedReturnType !== ReturnTypes.EARLY_RETURN &&
            contractStatus !== SubscriptionStatus.RETURN_SUBMITTED && 
            convertUnwindToETButton) 
            &&(
                        <Button
                          theme={theme}
                          secondary
                          text="Convert To Early Return"
                          onClick={() => {
                            setConvertETDialogOpened(true);
                          }}
                        />
             )}

          {!hideButton && (isDirty || addressDirty || (contractData?.subscription_status === SubscriptionStatus.ACTIVE && isTabLoaded === OrderTabName[OrderTabName.RETURN]) ) && (
            <Button
              disabled={
                isLoading ||
                !updateInsauranceDetail ||
                verificationLoading ||
                createChecklistLoading
              }
              theme={theme}
              primary
              text={getSubmitButtonText()}
              endIcon={
                (isLoading ||
                  verificationLoading ||
                  createChecklistLoading) && (
                  <img src={DisableLoader} alt="Loader" />
                )
              }
              onClick={handleSubmit((data) => handleSubmitRequest(data))}
            />
          )}
        </Box>
      </PageHeader>
      <Dialog
        theme={theme}
        title="Cancelation Reason"
        open={dialogOpened}
        disablePortal={true}
        children={
          <>
            <Box theme={theme}>
              <Box theme={theme} mt={1}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="subtitle1"
                  component="h4"
                >
                  Please specify the reason of Cancelation.
                </Typography>
                {isLoadingGet ? (
                  <Skeleton width={"100%"} height={20} />
                ) : (
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        label={"Reason"}
                        items={configureReason}
                        placeholder={"Select"}
                        disablePortal={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                )}

                <Grid theme={theme} item xs={12}>
                  {watch("reason") == "OTHER" && (
                    <Controller
                      name="cancelleation_detail"
                      control={control}
                      defaultValue={""}
                      rules={{
                        maxLength: {
                            value: 100,
                            message: ValidationMessages.CANCELLATION_REASON_LENGTH,
                        }
                    }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          type="text"
                          theme={theme}
                          label="Specify Other"
                          fullWidth
                          placeholder="Specify Other"
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            clearErrors("cancelleation_detail")
                        } }
                        error={errors?.cancelleation_detail?.message?.toString()}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  onClick={() => {
                    setDialogOpened(false);
                  }}
                  text={"Cancel"}
                ></Button>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  disabled={saveButtonLoading}
                  endIcon={
                    saveButtonLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                  onClick={handleSubmit((data) => {
                    handleDoneEvent(data);
                  })}
                  text={"Save"}
                ></Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={
          initiateReturnDialog ||
          updateReturnDialog ||
          cancelSubscriptionDialog ||
          initiateUnwindDialog ||
          buyOutDialog
        }
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                {cancelSubscriptionDialog && (
                  <Typography
                    theme={theme}
                    mb={2}
                    className="content-title"
                    variant="h2"
                    component="h2"
                  >
                    Are you sure you want to cancel subscription?
                  </Typography>
                )}
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant={cancelSubscriptionDialog ? "body2" : "h2"}
                  component={cancelSubscriptionDialog ? "span" : "h2"}
                >
                  {getTypoGraphyText()}
                </Typography>
                {initiateReturnDialog && returnType === ReturnTypes.UNWIND &&
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant={ "body2"}
                  component={ "span"}
                >
                  Clicking confirm will allow the customer to return their car and result in a refund upon return.
                </Typography>}
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text={initiateReturnDialog ? "No" : "Cancel"}
                  onClick={() => {
                    setInitiateReturnDialog(false);
                    setUpdateReturnDialog(false);
                    setSubscriptionDialog(false);
                    setInitiateUnwindDialog(false);
                    setBuyOutDialog(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  disabled={returnLoading || subscriptionLoading}
                  text={initiateReturnDialog ? "Yes" : "Confirm"}
                  onClick={handleSubmit((data) => handleOnClick(data))}
                  endIcon={
                    (returnLoading || subscriptionLoading) && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={returnDialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to cancel the customer's Early Termination request?
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                 The customer will no longer be able to return to a store until a new Early Termination request is made or their Standard Return period begins.
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setReturnDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  disabled={cancelReturnLoading}
                  theme={theme}
                  primary
                  text="Save"
                  onClick={() => {
                    handleCancelReturnRequest();
                  }}
                  endIcon={
                    cancelReturnLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
       <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={convertETDialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to change the return type from Unwind to Early Return?
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  This action cannot be undone.
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setConvertETDialogOpened(false);
                    setConvertedRetrnType("Unwind")
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  disabled={convertUnwindLoading}
                  theme={theme}
                  primary
                  text="Save"
                  onClick={handleSubmit((data) => {
                    onSubmit(data);
                    setConvertedRetrnType("EarlyReturn")
                  })}
                  endIcon={
                    convertUnwindLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
