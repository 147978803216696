import axios, { AxiosError } from "axios";
import {
    decodeADAccessToken,
    getInternalAccessToken,
    setInternalAccessToken,
    setExternalSubscriptionKey,
    getExternalSubscriptionKey,
} from "./methods";
import { loginRequest } from "../authConfig";
import type { IADUserInfo, IUserInfo } from "Interface";
import { ACTIVE_DIRECTORY_USER, GET_TENENT_CONFIG } from "common/internalEndpointUrls";
import { msalInstance } from "root.component";

export const deluxBaseUrl = process.env.DELUX_API;
export const indexBaseUrl = process.env.INDEX_API;
export const eotBaseUrl = process.env.EOT_API;
export const marketPlaceBaseUrl = process.env.MARKET_PLACE_API;
export const externalBaseUrl = process.env.EXTERNAL_API;
export const hubexBaseUrl = process.env.HUBEX_API;

export class ApiError extends Error {
    name: string;

    constructor (
        public response: Response,
        public data?: ErrorDataType,
        message?: string
    ) {
        super(message || response.statusText);
        this.name = this.constructor.name;
    }
}

export type ErrorDataType = {
    status?: number;
    errorCode?: string;
    message?: string;
};

export const sendRequest = (baseUrl, customHeaders = {}) => {
    const axioxInst = axios.create({
        baseURL: baseUrl,
        withCredentials: false,
        headers: {
            "Content-type": "application/json",
            ...customHeaders,
        },
    });

    axioxInst.interceptors.request.use(async (req) => {
        switch (req.baseURL) {
            case externalBaseUrl:
                try {
                    const response = await msalInstance.acquireTokenSilent({
                        ...loginRequest,
                        account: msalInstance.getAllAccounts()[0],
                    });
                    const externalSubscriptionKey: string = getExternalSubscriptionKey();
                    if (externalSubscriptionKey != null) {
                        req.headers["Ocp-Apim-Subscription-Key"] = externalSubscriptionKey;
                    } else {
                        const { data }  = await tenantConfigApi(getInternalAccessToken()?.access_token).get(GET_TENENT_CONFIG);
                        setExternalSubscriptionKey(data?.external_subscription_key);
                        req.headers["Ocp-Apim-Subscription-Key"] = data?.external_subscription_key;
                    }
                    req.headers["Authorization"] = `Bearer ${response.accessToken}`;
                } catch (e) {
                    // Need to uncomment when adhoc-invoices implementation will be completed
                    // msalInstance.loginRedirect(loginRequest).catch(e => {
                    //   console.log(e);
                    // });
                }
                break;
            case "SIGNED_URL":
                    break;
            default: {
                const internalAccessToken: IUserInfo = getInternalAccessToken();
                if (internalAccessToken !== null) {
                    // Authorization token implementation is currently incomplete from BE side so
                    // that's why it is temporary commented from FE side.
                     req.headers['Authorization'] = `Bearer ${internalAccessToken.access_token}`
                    // req.headers["x-api-key"] = process.env.X_API_KEY;
                } else {
                    // Getting Azure AD Token
                    // acquireTokenSilent: Try to get token from browser memory
                    // and if not found then go on server.
                    try {
                        const response = await msalInstance.acquireTokenSilent({
                            ...loginRequest,
                            account: msalInstance.getAllAccounts()[0],
                        });

                        const adUserInfo: IADUserInfo = decodeADAccessToken(
                            response.accessToken
                        );
                        // Getting Internal Token
                        const { data } = await marketPlaceApi.post(ACTIVE_DIRECTORY_USER, {
                            email: adUserInfo.user_name,
                            job_code: adUserInfo.job_code,
                            ad_tenant_id: adUserInfo.tenant_id,
                        });

                        setInternalAccessToken(data);
                        // Authorization token implementation is currently incomplete from BE side so
                        // that's why it is temporary commented from FE side.
                        req.headers['Authorization'] = `Bearer ${data.access_token}`;
                        //req.headers["x-api-key"] = process.env.X_API_KEY;

                    } catch (error) {
                        // Need to uncomment when adhoc-invoices implementation will be completed
                        // msalInstance.loginRedirect(loginRequest).catch(e => {
                        //   console.log(e);
                        // });
                    }
                }
                break;
            }
        }

        return req;
    });

    axioxInst.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError) => {
            const status = error?.response?.status;
            switch (status) {
                // case 404:
                //   return Promise.resolve(error?.response?.data);
                default:
                    return Promise.reject(error?.response?.data);
            }
        }
    );
    return axioxInst;
};

export const marketPlaceApi = axios.create({
    baseURL: marketPlaceBaseUrl,
    withCredentials: false,
    headers: {
        "Content-type": "application/json",
    },
});

const tenantConfigApi = (access_token) => {
    return axios.create({
        baseURL: deluxBaseUrl,
        withCredentials: false,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${access_token}`
        },
    });
}

export const invoiceRequest = () => {
    const axiosInstance = axios.create({
        baseURL: `${process.env.STRIPE_API}`,
        withCredentials: false,
        headers: {
            Authorization: `Bearer ${process.env.STRIPE_SECRET_KEY}`,
        },
    });

    axiosInstance.interceptors.request.use(
        async (req) => {
            switch (req.method) {
                case "post":
                    req.headers["Content-type"] = "application/x-www-form-urlencoded";
                    break;
                default:
                    req.headers["Content-type"] = "application/json";
                    break;
            }
            return req;
        },
        (err) => {
            console.log(err);
        }
    );

    return axiosInstance;
};
